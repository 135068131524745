import React from 'react'
import { navigate } from "gatsby"
import { primaryNav, secondaryNav, closeMenu } from '../../services/sideMenu'

const SideMenu: React.FC = () => {

    const navigateTo = (url: string) => {
        closeMenu();
        navigate(url)
    }

    return (
        <div className="wrap-dropdown" role="button" tabIndex={0}>
            <div
                aria-label="overrlay"
                className="overlay"
                role="button"
                tabIndex={0}
                onClick={() => closeMenu()}
            />
            <div className="com-dropdown">
                <section className="header__dropdown">
                    <a
                        onClick={() => navigateTo('/')}
                        title="Ir a la página principal"
                        className="com-link"
                    >
                        <i className="com-logo logo-la-nacion --color" />
                    </a>
                    <button
                        type="button"
                        className="com-button --icon"
                        title="Cerrar"
                        tabIndex={0}
                        onClick={() => closeMenu()}
                    >
                        <i className="com-icon icon-close" />
                    </button>
                </section>
                <section className="menu__dropdown">
                    <nav className="nav__dropdown">
                        <ul className="list__nav first--nav">
                            {
                                primaryNav.map((item, i) => {
                                    return (
                                        <li key={i} className={`item__nav ${item.children ? 'has--children' : ''} item--${item.title.toLowerCase()} item--disabled`}>
                                            <a
                                                onClick={() => navigateTo(item.url)}
                                                className="link__item"
                                                title={`Ir a ${item.title}`}
                                            >
                                                {item.title}
                                            </a>
                                            {
                                                item.children ?
                                                    <>
                                                        <button type="button" className="button__item">
                                                            <i className="icon-arrow-down" />
                                                        </button>
                                                        <ul className="sublist__nav">
                                                            {
                                                                item.children.map((subItem, i) => {
                                                                    return (
                                                                        <li key={i} className="item__nav">
                                                                            <a
                                                                                onClick={() => navigateTo(subItem.url)}
                                                                                className="link__item"
                                                                                title={`Ir a ${subItem.title}`}
                                                                            >
                                                                                {subItem.title}
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                })
                                                            }

                                                        </ul>
                                                    </>
                                                    : null
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <ul className="list__nav secondary--nav">
                            {
                                secondaryNav.map((item, i) => {
                                    return (
                                        <li key={i} className={`item__nav ${item.children ? 'has--children' : ''} item--${item.title.toLowerCase()} item--disabled`}>
                                            <a
                                                onClick={() => navigateTo(item.url)}
                                                className="link__item"
                                                title={`Ir a ${item.title}`}
                                            >
                                                {item.title}
                                            </a>
                                            {
                                                item.children ?
                                                    <>
                                                        <button type="button" className="button__item">
                                                            <i className="icon-arrow-down" />
                                                        </button>
                                                        <ul className="sublist__nav">
                                                            {
                                                                item.children.map((subItem, i) => {
                                                                    return (
                                                                        <li key={i} className="item__nav">
                                                                            <a
                                                                                onClick={() => navigateTo(subItem.url)}
                                                                                className="link__item"
                                                                                title={`Ir a ${subItem.title}`}
                                                                            >
                                                                                {subItem.title}
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                })
                                                            }

                                                        </ul>
                                                    </>
                                                    : null
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </nav>
                </section>
            </div>
        </div>
    )
}

export default SideMenu
