import React from 'react'
import { navigate } from "gatsby"
import { openMenu } from '../../services/sideMenu'

const Header: React.FC = () => {

    const navigateTo = (url: string) => {
        navigate(url)
    }

    return (
        <>
            <header id="header" className="header">
                <div className="lay">
                    <div className="row">
                        <div className="col-4 header__left">
                            <div className="com-hamburger">
                                <button
                                    type="button"
                                    className="com-button  --tertiary --icon menu "
                                    title="Secciones"
                                    onClick={() => openMenu()}
                                >
                                    <i className="com-icon icon-menu" />
                                    <span className=" com-text">Secciones</span>
                                </button>
                            </div>
                            <label
                                id="querylyButton"
                                htmlFor="queryly_toggle"
                                title="Ir al buscador"
                            >
                                <i className="com-button --tertiary --icon queryly_searchicon">
                                    <i className="com-icon icon-search" />
                                    BUSCAR
                                </i>
                            </label>
                        </div>
                        <div className="col-7 col-desksm-4 header__middle">
                            <a
                                onClick={() => navigateTo('/')}
                                title="Ir a la página principal"
                                className="com-link"
                            >
                                <i className="com-logo logo-la-nacion --color" />
                            </a>
                            <div style={{ marginTop: '.25rem', fontSize: ".9rem" }}>
                                Sábado 15 de marzo de 2025
                            </div>
                        </div>
                        <div className="col-4 header__right">
                            <div id="user-menu" className="com-usuario">
                                <button
                                    className="com-button --special"
                                    id="btnsuscribite"
                                    type="button"
                                    title="Suscribite"
                                >
                                    SUSCRIBITE
                                </button>
                                <button
                                    className="com-button --secondary"
                                    id="btningresar"
                                    type="button"
                                    title="Ingresar"
                                >
                                    INGRESAR
                                </button>
                            </div>
                        </div>
                        <div className="col-1 header__search">
                            <label htmlFor="queryly_toggle">
                                <i className="com-icon icon-search queryly_searchicon" />
                            </label>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
