export const primaryNav = [
    {
        title: 'Últimas noticias',
        url: '/',
        children: [
            {
                title: 'Tránsito y transporte',
                url: '/'
            },
            {
                title: 'Clima',
                url: '/'
            },
            {
                title: 'LA NACION Data',
                url: '/'
            }
        ]
    },
    {
        title: 'Política',
        url: '/',
    },
    {
        title: 'Economía',
        url: '/',
        children: [
            {
                title: 'Dólar Hoy',
                url: '/'
            },
            {
                title: 'Campo',
                url: '/'
            },
            {
                title: 'Propiedades',
                url: '/'
            },
            {
                title: 'Comercio Exterior',
                url: '/'
            },
            {
                title: 'Autos',
                url: '/'
            },
            {
                title: 'Índices',
                url: '/'
            },
            {
                title: 'Calculadora de ganancias',
                url: '/'
            },
        ]
    },
    {
        title: 'El Mundo',
        url: '/',
    },
    {
        title: 'Sociedad',
        url: '/',
        children: [
            {
                title: 'Buenos Aires',
                url: '/'
            },
            {
                title: 'Seguridad',
                url: '/'
            },
            {
                title: 'Educación',
                url: '/'
            },
            {
                title: 'Cultura',
                url: '/'
            },
            {
                title: 'Comunidad',
                url: '/'
            },
            {
                title: 'Salud',
                url: '/'
            },
            {
                title: 'Ciencia',
                url: '/'
            },
            {
                title: 'Guías: Hablemos de...',
                url: '/'
            },
        ]
    },
    {
        title: 'Opinión',
        url: '/',
        children: [
            {
                title: 'Columnistas',
                url: '/'
            },
            {
                title: 'Editoriales',
                url: '/'
            },
            {
                title: 'Cartas de Suscriptores',
                url: '/'
            }
        ]
    },
    {
        title: 'Deportes',
        url: '/',
        children: [
            {
                title: 'Fútbol',
                url: '/'
            },
            {
                title: 'Estadísticas',
                url: '/'
            },
            {
                title: 'Rugby',
                url: '/'
            },
            {
                title: 'Tenis',
                url: '/'
            }
        ]
    },
    {
        title: 'Lifestyle',
        url: '/',
        children: [
            {
                title: 'Moda y Belleza',
                url: '/'
            },
            {
                title: 'Turismo',
                url: '/'
            },
            {
                title: 'Tecnología',
                url: '/'
            },
            {
                title: 'Horóscopo',
                url: '/'
            },
            {
                title: 'Feriados',
                url: '/'
            },
            {
                title: 'Loterías y quinielas',
                url: '/'
            },
            {
                title: 'Cocina y recetas',
                url: '/'
            },
            {
                title: 'Podcasts',
                url: '/'
            },
            {
                title: 'Sudoku',
                url: '/'
            },
        ]
    },
]

export const secondaryNav = [
    {
        title: 'Edición Impresa',
        url: '/',
        children: [
            {
                title: 'Acceso PDF',
                url: '/'
            },
            {
                title: 'LA NACION Revista',
                url: '/'
            },
            {
                title: 'Sábado',
                url: '/'
            },
            {
                title: 'Ideas',
                url: '/'
            },
            {
                title: 'Editoriales',
                url: '/'
            },
            {
                title: 'Carta de lectores',
                url: '/'
            },
            {
                title: 'Avisos fúnebres',
                url: '/'
            },
            {
                title: 'Avisos sociales',
                url: '/'
            },
        ]
    },
    {
        title: 'Revistas',
        url: '/',
        children: [
            {
                title: 'Revista OHLALÁ!',
                url: '/'
            },
            {
                title: 'Revista ¡HOLA!',
                url: '/'
            },
            {
                title: 'Revista Rolling Stone',
                url: '/'
            },
            {
                title: 'Revista Lugares',
                url: '/'
            },
            {
                title: 'Revista Living',
                url: '/'
            },
            {
                title: 'Revista Brando',
                url: '/'
            },
            {
                title: 'Revista Jardín',
                url: '/'
            },
        ]
    },
    {
        title: 'LN+',
        url: '/',
    },
    {
        title: 'Kiosco LA NACION',
        url: '/',
    },
    {
        title: 'Club LA NACION',
        url: '/',
    },
]

export function openMenu() {
    document.body.classList.add("dropdown");
}

export function closeMenu() {
    document.body.classList.remove("dropdown");
}