import React, { useEffect, useState } from "react";
import Pass from "../Pass";

const Layout = ({ children }) => {
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    setEnabled(typeof window !== "undefined" ? localStorage.getItem("canEnter") === process.env.GATSBY_ENABLED : false)
  }, []);

  const letItIn = (canEnter) => {
    setEnabled(canEnter);
    if (typeof window !== "undefined" && canEnter) {
      localStorage.setItem("canEnter", process.env.GATSBY_ENABLED);
    }
  }

  return (
    <div>
      {!enabled && (
        <div>
          <Pass canEnter={(canEnter) => letItIn(canEnter)} />
        </div>
      )}
      {enabled && <div>{children}</div>}
    </div>
  );
};

export default Layout;
