import { Link } from 'gatsby';
import React from 'react'

const Footer: React.FC = () => {

    return (
        <footer className="footer-site --threexs">
            <div className="lay">
                <section className="top">
                    <div className="row">
                        <div className="col-desksm-4 --left">
                            <a
                                className="com-link"
                                href="https://www.facebook.com/lanacion"
                                target="_blank"
                                rel="_blank"
                                title="Seguirnos en Facebook"
                            >
                                <i className="com-icon icon-facebook-filled " />
                            </a>
                            <a
                                className="com-link"
                                href="https://twitter.com/LANACION"
                                target="_blank"
                                rel="_blank"
                                title="Seguirnos en Twitter"
                            >
                                <i className="com-icon icon-twitter-filled " />
                            </a>
                            <a
                                className="com-link"
                                href="https://www.instagram.com/lanacioncom"
                                target="_blank"
                                rel="_blank"
                                title="Seguirnos en Instagram"
                            >
                                <i className="com-icon icon-instagram " />
                            </a>
                            <a
                                className="com-link"
                                href="https://www.lanacion.com.ar/arc/outboundfeeds/rss/?outputType=xml"
                                target="_blank"
                                rel="_blank"
                                title="Ir a Rss"
                            >
                                <i className="com-icon icon-rss " />
                            </a>
                        </div>
                        <div className="col-desksm-4 --center">
                            <Link
                                to={"/"}
                                title="Ir a la página principal"
                                className="com-link"
                            >
                                <i className="com-logo logo-la-nacion --color --sm" />
                            </Link>
                        </div>
                        <div className="col-desksm-4 --right">
                            <a
                                href="https://play.google.com/store/apps/details?id=app.lanacion.activity&hl=es_419"
                                rel="nonoopener noreferrer"
                                target="_blank"
                                title="Descargar nuestra app en Google Play"
                                className="com-link"
                            >
                                <img
                                    src="https://arc-static.glanacion.com/pf/resources/images/google-play.svg?d=400"
                                    alt="Descargar nuestra app en Google Play"
                                    width={120}
                                    height={35}
                                    className="com-image"
                                    loading="lazy"
                                />
                            </a>
                            <a
                                href="https://apps.apple.com/ar/app/la-nacion/id410689702"
                                rel="nonoopener noreferrer"
                                target="_blank"
                                title="Descargar nuestra app en el App Store"
                                className="com-link"
                            >
                                <img
                                    src="https://arc-static.glanacion.com/pf/resources/images/app-store.svg?d=400"
                                    alt="Descargar nuestra app en el App Store"
                                    width={120}
                                    height={35}
                                    className="com-image"
                                    loading="lazy"
                                />
                            </a>
                        </div>
                    </div>
                </section>
                <section className="middle">
                    <div className="row">
                        <ul className="mod-list --inline --font-bold">
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Últimas noticias"
                                    className="com-link"
                                >
                                    Últimas noticias
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Política"
                                    className="com-link"
                                >
                                    Política
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Economía"
                                    className="com-link"
                                >
                                    Economía
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a El mundo"
                                    className="com-link"
                                >
                                    El mundo
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Sociedad"
                                    className="com-link"
                                >
                                    Sociedad
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Opinión"
                                    className="com-link"
                                >
                                    Opinión
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Deportes"
                                    className="com-link"
                                >
                                    Deportes
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Lifestyle"
                                    className="com-link"
                                >
                                    Lifestyle
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Espectáculos"
                                    className="com-link"
                                >
                                    Espectáculos
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Edición impresa"
                                    className="com-link"
                                >
                                    Edición impresa
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a LN+"
                                    className="com-link"
                                >
                                    LN+
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Club LA NACION"
                                    className="com-link"
                                >
                                    Club LA NACION
                                </Link>
                            </li>
                        </ul>
                        <ul className="mod-list --inline --magazine --font-bold --fourxs">
                            <li>Revistas</li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Revista OHLALÁ"
                                    className="com-link"
                                >
                                    OHLALÁ!
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Revista HOLA"
                                    className="com-link"
                                >
                                    ¡HOLA!
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Revista Rolling Stone"
                                    className="com-link"
                                >
                                    ROLLING STONE
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Revista Living"
                                    className="com-link"
                                >
                                    LIVING
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Revista Brando"
                                    className="com-link"
                                >
                                    BRANDO
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Revista Jardín"
                                    className="com-link"
                                >
                                    JARDÍN
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    title="Ir a Revista Lugares"
                                    className="com-link"
                                >
                                    LUGARES
                                </Link>
                            </li>
                        </ul>
                        <ul className="mod-list --inline --club --font-bold --fourxs">
                            <li>Club del vino:</li>
                            <li>
                                <Link
                                    to={'/'}
                                    rel="nonoopener noreferrer"
                                    target="_blank"
                                    title="Ir a tienda online de Bonvivir"
                                    className="com-link"
                                >
                                    Bon vivir
                                </Link>
                            </li>
                        </ul>
                        <ul className="mod-list --inline --delivery --font-bold --fourxs">
                            <li>Envíos:</li>
                            <li>
                                <Link
                                    to={'/'}
                                    rel="nonoopener noreferrer"
                                    target="_blank"
                                    title="Ir a página de envíos de HOP"
                                    className="com-link"
                                >
                                    HOP
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    rel="nonoopener noreferrer"
                                    target="_blank"
                                    title="Ir a tienda online de Colecciones LA NACION"
                                    className="com-link"
                                >
                                    Colecciones
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    rel="nonoopener noreferrer"
                                    target="_blank"
                                    title="Ir a la Maestría en periodismo"
                                    className="com-link"
                                >
                                    Máster en periodismo
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    rel="nonoopener noreferrer"
                                    target="_blank"
                                    title="Ir a la Fundación LA NACION"
                                    className="com-link"
                                >
                                    Fundación LA NACION
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                    rel="nonoopener noreferrer"
                                    target="_blank"
                                    title="Ir a los clasificados solidarios"
                                    className="com-link"
                                >
                                    Avisos solidarios
                                </Link>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="bottom">
                    <div className="row">
                        <div className="col-desksm-9 col-deskxl-8 --left">
                            <ul className="mod-list --inline --bullet-xs">
                                <li>
                                    <Link
                                        to={'/'}
                                        title="Ir al mapa del sitio de LA NACION"
                                        className="com-link"
                                    >
                                        Mapa del sitio
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={'/'}
                                        title="Ir a las preguntas frecuentes"
                                        className="com-link"
                                    >
                                        Ayuda
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={'/'}
                                        title="Ir a los términos y condiciones"
                                        className="com-link"
                                    >
                                        Términos y condiciones
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={'/'}
                                        rel="nonoopener noreferrer"
                                        target="_blank"
                                        title="Cómo anunciar en el sitio de LA NACION"
                                        className="com-link"
                                    >
                                        ¿Cómo anunciar?
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={'/'}
                                        title="Ir a las suscripciones al diario"
                                        className="com-link"
                                    >
                                        Suscribirse al diario impreso
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-desksm-3 col-deskxl-4 --right">
                            <ul className="mod-list --inline">
                                <li>Protegido por reCAPTCHA:</li>
                                <li>
                                    <a
                                        href="https://policies.google.com/terms?hl=es-419"
                                        rel="nonoopener noreferrer"
                                        target="_blank"
                                        title="Ir a las condiciones de Google"
                                        className="com-link"
                                    >
                                        Condiciones
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://policies.google.com/privacy?hl=es-419"
                                        rel="nonoopener noreferrer"
                                        target="_blank"
                                        title="Ir a la privacidad de Google"
                                        className="com-link"
                                    >
                                        Privacidad
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-desksm-6 --right">
                            <img
                                src="https://arc-static.glanacion.com/pf/resources/images/gda.svg?d=400"
                                alt="gda"
                                width={36}
                                height={20}
                                className="com-image --gda"
                                loading="lazy"
                            />
                            <span className=" com-text">
                                Miembro de GDA. Grupo de Diarios América
                            </span>
                            <a
                                href="http://qr.afip.gob.ar/?qr=HJMakbCpenWNdXYfqXtEDQ,,"
                                rel="nonoopener noreferrer"
                                target="_blank"
                                title="Data fiscal"
                                className="com-link"
                            >
                                <img
                                    src="https://arc-static.glanacion.com/pf/resources/images/data-fiscal.svg?d=400"
                                    alt="Data fiscal"
                                    width={28}
                                    height={38}
                                    className="com-image --data"
                                    loading="lazy"
                                />
                            </a>
                        </div>
                        <div className="col-desksm-6 --left">
                            <p className=" com-text --threexs">
                                Copyright 2025 SA LA NACION | Todos los derechos
                                reservados
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </footer>
    )
}

export default Footer
