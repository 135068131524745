import { Link } from 'gatsby'
import React from 'react'

const NavMobile: React.FC = () => {
    return (
        <nav className="com-nav-mobile">
            <div className="row">
                <Link
                    className="com-link col-3 item-foo"
                    to={'/'}
                    rel="nonoopener noreferrer"
                >
                    <i className="com-icon icon-home" />
                    <p>Home</p>
                </Link>
                <button type="button" className="col-3 item-foo">
                    <i className="com-icon icon-sections" />
                    <p>Secciones</p>
                </button>
                <Link
                    className="com-link col-3 item-foo"
                    to={'/'}
                    rel="nonoopener noreferrer"
                >
                    <i className="com-icon icon-club" />
                    <p>Club LA NACION</p>
                </Link>
                <Link
                    className="com-link col-3 item-foo"
                    to={'/'}
                    rel="nonoopener noreferrer"
                >
                    <i className="com-icon icon-user " />
                    <p>Mi Cuenta</p>
                </Link>
            </div>
        </nav>
    )
}

export default NavMobile
